import { Component, OnInit } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilityService } from '../services/utility.service';
import { ApiService } from '../services/api.service';
import { KIOSK_AUTO_LOGIN, DOMAIN_URL, page_redirection_time, setIntervalTimeOut, terminalCheck, fetchTestUrl } from '../common/api';
import { KioskapiService } from '../services/kioskapi.service';
import { TranslateConfigService } from '../services/translate-config.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.page.html',
  styleUrls: ['./product-detail.page.scss'],
})
export class ProductDetailPage implements OnInit {
  params : any;
  master_menu_type:any;
  addOnPrice: any = {};
  addonsList = [];
  item: any = [];
  radioAddons = {}; selected_addons = [];
  formFields: any ={};
  addons: Array<string> = [];
  quantity = 1;
  total: number = 0;
  itemprice: number = 0;
  itembaseprice: number = 0;
  total_base_price: number;
  totalAddonPriceArray: any=[];
  totalQuantity: any=[];
  currency = "¥";
  showDecimal= true;
  bookmark_customization: any = {};
  requestFromCombo: boolean = false;
  totalvalue:number;
  nutrients:any =[];
 nutrientsValue: any=[];
 obj_productDetails;
 terminalId;
 telemetry_poller;
 terminal_modal;
 addons_select;
 addon_array =[];
 showFooter = false;
 app_settings;
 restaurantData;
 classForEvent = '';
 disableEventButton = false;
 notiAnimationClass = '';
 maxDrinknotiAnimationClass = '';
 tot = 1;
 disableAddToCartButton = false;
 intervalId:any;
  constructor(
    private router: Router,
    private kioskauthservice:KioskauthService,
    private route: ActivatedRoute,
    private store:LocalStoreService,
    public alertController: AlertController,
    private kiosk_apiService : KioskapiService,
    private utilityService: UtilityService,
    private api: ApiService,
    private translateConfigService: TranslateConfigService,
    public toastController: ToastController
  ) { 
    this.showDecimal= true;
    if(this.store.getCountry()) {
        let countryData = this.store.getCountry();
        this.currency   = countryData['master_country_currency_code'];
        if(countryData['master_country_currency_decimal_places'] == 0){
            this.showDecimal= false;
        }
    }
  }

  ngOnInit() {
    
  }

  ionViewWillEnter() {
    this.notiAnimationClass = '';
    this.maxDrinknotiAnimationClass = '';
    this.item = [];
    this.showFooter = false;
    this.terminal_modal = this.store.get('terminal_modal');
    this.restaurantData = this.store.getRestaurant();
    this.disableAddToCartButton = false;
    setInterval( () =>{
      this.terminal_modal = this.store.get('terminal_modal')
    },500)
    this.kiosk_apiService.telemetry();
    this.telemetry_poller = setInterval(
      ()=>{
       this.kiosk_apiService.telemetry()
      }
    , terminalCheck);

    this.reset_time();
    this.kiosk_apiService.logEvents("PRODUCT_DETAIL");
    this.kiosk_apiService.audit_log("PRODUCT_DETAIL");
    this.radioAddons = [];
    this.store.remove('comboAddonPrice');
    this.store.set('formFieldsCombo',[]);
    this.store.set('formFields', []);
    this.kioskauthservice.isRestaurantOperational();
    this.payment_printing_setting();
    if(this.route.snapshot.queryParamMap.get('ci')) {
      let items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
      let cartIndex = this.route.snapshot.queryParamMap.get('ci');
      this.quantity = items[cartIndex].length;
      this.requestFromCombo = true;
    }
    this.store.remove('formFields')
    this.getProductDetail();
    this.getTranslatedText();
    this.terminalId = this.store.get('terminalid');
    this.translateConfigService.getDefaultLanguage();
    this.classForEvent = this.restaurantData.master_restaurant_code == 'EVENT' ? 'eventkiosk' : '';

    //reset the timer:
    this.resetTimerInterval();
  }
 
  groupSectiontypeIsMultiple(inputType) {
    if (inputType == 'MULTIPLE') {
      return true;
    } else {
      return false;
    }
  }

  showAmountSection(amount) {
    if (amount == 0 || amount == 0.00) {
      return false;
    } else {
      return true;
    }
  }

addons_select_updated;
  async getProductDetail(){
    let item_uuid = this.route.snapshot.queryParamMap.get('id');
    let data = {
      "ref":"kiosk",
      master_menu_item_uuid: item_uuid,
      user_information_uuid: "",
      restaurant_uuid: this.store.getRestaurantuuid('master_restaurant_uuid'),
    };
    this.kiosk_apiService.Item_detail(data)
      .subscribe(res => {
        if(res.aaData.is_food_category_time_available == false){
          this.kioskauthservice.itemNotAvailableAlert();
          return false;
        } 
        this.itemprice = 0;
        this.item = res.aaData;
        this.master_menu_type = res.aaData.master_menu_type;
        this.total = Number(res.aaData.master_menu_item_price);  
        this.total_base_price = Number(res.aaData.master_menu_item_price);
        this.itembaseprice = Number(res.aaData.master_menu_item_price);
        this.total = this.total + res.aaData.bookmark_customization_total_amount;
        this.itemprice = this.total;
        this.showFooter = true;
        this.calculateTotal();
        if(this.item.bookmark_customization != ""){
          this.addons_select = JSON.parse(this.item.bookmark_customization);
          this.addons_select_updated = JSON.parse(this.item.bookmark_customization);
          Object.entries(this.addons_select_updated).forEach(([k, v]) => {
            console.log("itemPreSeletcs", k, v)
              Object.entries(this.item.add_ons).forEach(([k1, v1]) => {
                Object.entries(v1).forEach(([k2, v2]) => {
                  console.log("add_onObj k2", k2, v2)
                  const filteredObj = v2.filter((singleAddOn)=> singleAddOn.master_addon_internal_name == v);
                  const filteredObjIndex = v2.findIndex((singleAddOn)=> singleAddOn.master_addon_internal_name == v);
                  console.log("filteredObj", filteredObj)
                  if (filteredObj.length > 0) {
                    this.item.add_ons[k1][k2][filteredObjIndex].customSelect = true;
                    const selectedAddonData = {name:filteredObj[0].master_addon_name,internal_name:filteredObj[0].master_addon_internal_name, addon_id:filteredObj[0].master_addon_uuid}
                    this.addons_select_updated[k] = [selectedAddonData];
                    console.log("this.addons_select", this.addons_select_updated) 
                  }
                })
              })
          })
          }
          else{
            this.addons_select = []
          }
          
        this.get_prefilled_values(0);
        
      },
      err =>{
        this.technical_error();
      }
      )
    
  } 

  addQuantity() {
    let cartDetail = this.store.getUserCart();
    let allowedMaxDrinks = this.store.get('ellaMaxLimitCount');
    var cartTotalItemCount = this.quantity;
    Object.entries(cartDetail).forEach(([k, v]) => {
      cartTotalItemCount = +v[0].total_quantity + +cartTotalItemCount;
      console.log('cartTotalItemCount inside', cartTotalItemCount);
    });
    if (cartTotalItemCount < allowedMaxDrinks) {
      this.quantity = this.quantity + 1;
      this.calculateTotal();
      this.get_prefilled_values(this.quantity);
      this.calculateTotalNumberOfItems();
    } else {
      this.maxDrinknotiAnimationClass = 'showNotification';
    }
  }

  removeQuantity() {
    if (this.quantity - 1 < 1) {
      return false;
    }
    this.quantity = this.quantity - 1;
    this.calculateTotal();
    // Unset object
    let counter = this.quantity;
    let existingData = this.store.get('formFields');
    delete existingData[counter];
    this.store.set('formFields', existingData);

    let i = 0;
    let addOnAmount: number = 0;

    Object.entries(this.totalAddonPriceArray).forEach(([k, v]) => {
      if(counter == i){
        addOnAmount = Number(v) + +addOnAmount;
      }
      i++;
    })
    delete this.totalAddonPriceArray[counter];
    this.calculateTotalNumberOfItems();
  }

  calculateTotalNumberOfItems() {
    let cartDetail = this.store.getUserCart();
    let allowedMaxDrinks = this.store.get('ellaMaxLimitCount');
    var cartTotalItemCount = this.quantity;
    Object.entries(cartDetail).forEach(([k, v]) => {
      cartTotalItemCount = +v[0].total_quantity + +cartTotalItemCount;
      console.log('cartTotalItemCount inside', cartTotalItemCount);
    });
    console.log('cartTotalItemCount outside', cartTotalItemCount);
    console.log('cartTotalItemCount >= allowedMaxDrinks', cartTotalItemCount >= allowedMaxDrinks);
    if (cartTotalItemCount >= allowedMaxDrinks) {
      this.maxDrinknotiAnimationClass = 'showNotification';
    } else {
      this.maxDrinknotiAnimationClass = '';
    }
  }

  calculateTotal() {  
     this.totalQuantity = [];
     for(let i=1;i<=this.quantity;i++){
        this.totalQuantity.push(i);
     } 
     this.total =  (+(this.quantity * this.itemprice));  
  }

  changeAddonTotal(event,counter,addon, obj?) {
    console.log("changeAddonTotal", event, counter, addon)
    const price = (document.getElementById(this.selectedInternalName)?.getAttribute('priceattr') !== undefined) ? Number(document.getElementById(this.selectedInternalName)?.getAttribute('priceattr')) : 0;
    console.log("price", price)
    if (event.target.checked) {
      this.radioAddons[event.target.name] = {
        name: event.target.value,
        price,
      };
      //this.addons.push(event.target.value);
      //this.store.set('formFields', this.addons);
      this.itemprice = (+this.itemprice) + (+price);
      console.log("itemprice", this.itemprice);
      if (event.target.tagName === 'ION-CHECKBOX') {
        console.log("event.target.tagName inside", event.target.tagName)
        let master_addon_group_uuid = obj['master_addon_group_uuid']
        let len = this.bookmark_customization[master_addon_group_uuid].length;
        if(event.target.checked){
          console.log("this.bookmark_customization 3 a", this.bookmark_customization)
          this.bookmark_customization[master_addon_group_uuid][len] = {name: obj.master_addon_name, internal_name: obj.master_addon_internal_name, addon_id:obj.master_addon_uuid}
          console.log("this.bookmark_customization 3", this.bookmark_customization)
        } else {
          console.log("this.bookmark_customization 4 a", this.bookmark_customization)
          this.bookmark_customization[master_addon_group_uuid] = this.bookmark_customization[master_addon_group_uuid].filter(item=>item.internal_name !==  obj.master_addon_internal_name )
          if (this.bookmark_customization[master_addon_group_uuid].length == 0) {
            delete this.bookmark_customization[master_addon_group_uuid];
          }
          console.log("this.bookmark_customization 4", this.bookmark_customization)
        }
      }
      this.calculateTotal();
    } else if (event.target.tagName === 'ION-RADIO-GROUP') {
      if (this.radioAddons[event.target.name]) {
        if (this.radioAddons[event.target.name].price != undefined) {
          this.itemprice = (+this.itemprice) - (+this.radioAddons[event.target.name].price);
        }
      }
      this.radioAddons[event.target.name] = {
        name: event.target.value,
        price,
      };
      this.itemprice = (+this.itemprice) + (+price);
      delete this.totalAddonPriceArray[counter+"_"+addon];
      this.totalAddonPriceArray[counter+"_"+addon] = this.itemprice;
      this.calculateTotal();
    } else {
      //alert(event.target.value+"===="+event.target.checked)
      delete(this.radioAddons[event.target.name])
      this.addons.splice(this.addons.indexOf(event.target.value), 1);
      this.itemprice = (+this.itemprice) - (+price);
      this.calculateTotal();
    }

  }
  selectedInternalName = '';
  async store_in_local(event,obj,counter) {
    this.selectedInternalName = obj.master_addon_internal_name + '_' + counter;
    console.log('this.selectedInternalName', this.selectedInternalName)
    console.log("store_in_local")
    if(counter > 0){
      counter = counter -1;
    }
    let master_addon_group_uuid = obj['master_addon_group_uuid'];
    if (obj.master_addon_group_selection_type == 'SINGLE') {
      if(this.bookmark_customization[master_addon_group_uuid]) {
        this.bookmark_customization[master_addon_group_uuid][0] = {name: obj.master_addon_name, internal_name: obj.master_addon_internal_name, addon_id:obj.master_addon_uuid};
      } else {
        this.bookmark_customization[master_addon_group_uuid] = [{name: obj.master_addon_name, internal_name: obj.master_addon_internal_name, addon_id:obj.master_addon_uuid}];
      }
    }
    else {
      let type_of_bookmark = typeof this.bookmark_customization[master_addon_group_uuid];
      if (type_of_bookmark == undefined || type_of_bookmark == 'undefined') {
        
        this.bookmark_customization[master_addon_group_uuid] = [];
      }
      
    }
   
    let addonsList = [];
 
  if(obj.master_addon_group_selection_type == "MULTIPLE"){
    if (event.target.checked) {
      if(obj.master_addon_name != null){
      delete  this.bookmark_customization[master_addon_group_uuid]
        
      }
   
    } else {
      let len = this.bookmark_customization[master_addon_group_uuid].length;
    if(obj.master_addon_name != null){
      this.bookmark_customization[master_addon_group_uuid][len] = {name: obj.master_addon_name, internal_name: obj.master_addon_internal_name, addon_id:obj.master_addon_uuid};
    
    }
      addonsList[counter] = this.bookmark_customization;
    }
  } 

  addonsList[counter] = this.bookmark_customization;
  let existingData = this.store.get('formFields');
  if(existingData != null) {
    let finalObj = Object.assign(existingData, addonsList);
    this.store.set('formFields', finalObj);
  } else {
    this.store.set('formFields', addonsList);
   
  } 
  this.calculateTotal();
  }

  get_prefilled_values(counter) {  
    if(counter > 0){
      counter = counter -1;
    }
    this.bookmark_customization= Object.create( {} );
    for (var k in this.item.add_ons) {
      let rows = this.item.add_ons[k];     
      for (var a in rows) {
        if (rows.hasOwnProperty(a)) {
          let row = rows[a];
          this.addon_array = row[0];
          let master_addon_group_uuid = row[0].master_addon_group_uuid;
         if(JSON.stringify(this.addons_select) == "[]"){
          this.bookmark_customization[master_addon_group_uuid]=[];
         }else{
          this.bookmark_customization = this.addons_select_updated;
          // this.bookmark_customization = this.addons_select;
         }
        
          // if(master_addon_group_uuid == 'e19c4ec81d7161fea86eeb2e4f37cb0d'){
          //   this.bookmark_customization[master_addon_group_uuid] = [row[0].master_addon_internal_name]
          // } else if(master_addon_group_uuid == '774fb98d062c89309d9bca4f72be9361'){
          //   this.bookmark_customization[master_addon_group_uuid] = [row[0].master_addon_internal_name]
          // } else {
          //   this.bookmark_customization[master_addon_group_uuid]=[];
          // }
         }
      }      
    }
    let addonsList = [];
        addonsList[counter] = this.bookmark_customization;
    let existingData = this.store.get('formFields');
    if(existingData != null) {
      let finalObj = Object.assign(existingData, addonsList);
      this.store.set('formFields', finalObj);
    } else {
      this.store.set('formFields', addonsList);
    }
  }

addOnsWithIntenalname = [];
addOnHasError = false;
  addToCart(item){
    this.disableAddToCartButton = true;
    this.addOnHasError = false
    this.disableEventButton = true;
    let data1 = this.store.get('formFields');
    console.log("data1", data1)
    let existingData1 = this.store.get('selectedItemsInCart');
    if (data1) {
      const items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
      if(this.route.snapshot.queryParamMap.get('ci')) {
        let cartIndex = this.route.snapshot.queryParamMap.get('ci');
        items.splice(cartIndex,1);
        this.store.setUserCart(items);
      }
      let data: any;
      let finalArray: any = [];
      var i=0;
      // for(var i=0; i<=this.quantity;i++) {
        this.addons = [];
        this.addOnsWithIntenalname = [];
            if(data1[i] != null){
              console.log("data1[i]", data1[i])
              if(item.master_menu_item_is_combo == 'NO') {
                Object.entries(data1[i]).forEach(([k, v]) => {
                  console.log("data1[i] foreach", v)
                  Object.entries(v).forEach(([k1, v1]) => {
                    console.log("data1[i] foreach v1", v1)
                    let addOnName: any= "";
                    let addOnDisplayName: any= "";
                        addOnName = v1['internal_name'];
                        addOnDisplayName = v1;
                        if(addOnName != null) {
                          this.addons.push(addOnName);
                          this.addOnsWithIntenalname.push(v1);
                        } else {
                          this.addOnHasError = true;
                          this.notiAnimationClass = 'showNotification';
                          setTimeout(() => {
                            this.notiAnimationClass = '';
                          }, 2000);
                          
                        }
                  })
                })
              }
            if(item.master_menu_item_is_combo == 'YES') {
              Object.entries(this.radioAddons).forEach(([k, v]) => {
                if(v['name'] && v['name'] != null) {
                  this.addons.push(v['name']);
                }
              });
            }
            let menu_item_total_price = item.master_menu_item_price;
            let addOnAmount: number = 0;
            Object.entries(this.totalAddonPriceArray).forEach(([kk, vv]) => {
                let arrayCounter: any = 0;
                let addOnPriceKey = kk.split("_");
                arrayCounter = addOnPriceKey[0];
                if(arrayCounter == i) {
                addOnAmount = +vv;
                menu_item_total_price = +vv + +menu_item_total_price;
                }
            })
            data = {
              id: this.route.snapshot.queryParamMap.get('id'),
              name: item.master_menu_item_name,
              thumb: item.master_menu_item_thumbnail ? item.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg',
              type: item.master_menu_type,
              resturant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
              menu_item_total_price: this.total/this.quantity,
              item_addon_price: addOnAmount,
              menu_item_price: item.master_menu_item_price,
              total: this.total,
              total_base_price: this.total_base_price,
              quantity: this.quantity, //this.quantity,
              addons: this.addons,
              note: this.store.note ? this.store.note : null,
              food_type: item.master_menu_type,
              total_quantity: this.quantity,
              addOnToDisplay: this.addOnsWithIntenalname
            };
            if (items) {
              finalArray.push(data);
            } else {
              finalArray.push(data);
             
            }
        }
      // }
      if (!this.addOnHasError){
        if (items) {
          items.push(finalArray);
          if (this.store.isLoggedIn()) {
            this.store.setUserCart(items);
          } else { 
            this.store.setCart(items); 
          }
        } else {
          if (this.store.isLoggedIn()) {
            this.store.setUserCart(finalArray);
          } else { 
            this.store.setCart([data]); 
          }
        } 
      }
    }
    if (!this.addOnHasError){
    setTimeout(() => {
      this.kiosk_apiService.logEvents("ADD_TO_CART");
      this.kiosk_apiService.audit_log("ADD_TO_CART")
    }, 200);
    let cartDetail = this.store.getUserCart();
    var cartItemCount = 0;
    Object.entries(cartDetail).forEach(([k, v]) => {
      cartItemCount = +v[0].total_quantity + +cartItemCount;
    });
    this.kiosk_apiService.restaurant_Operational()
      .subscribe(
        (res) => {
          let restaurantDetails = this.store.getRestaurant();
          if (restaurantDetails.master_restaurant_code == 'EVENT' || restaurantDetails.master_restaurant_code == 'event') {
            this.isItemCartAvaliable();
          } else {
            if (cartItemCount >= res.ella_max_drinks) {
              this.router.navigate([`cart-list`]);
            } else {
              this.router.navigate([`home`]);
            }
          }
        },
      );
    }
    return false;
    
  }
  
  redirectOnComboItemDetailPage(){
    this.store.set('formFieldsCombo',[]);
    this.store.set('comboAddonPrice',0);
    let addonArray = [];
    let comboAddonPrice = 0;
    Object.entries(this.radioAddons).forEach(([k, v]) => {
      if(v['name'] && v['name'] != null) {
        addonArray.push(v['name']);
        comboAddonPrice += v['price']
      }
    });
    this.store.set('formFieldsCombo',addonArray);
    this.store.set('comboAddonPrice',comboAddonPrice);
    this.router.navigateByUrl(`product-combo-detail?id=` + this.item.master_menu_item_uuid+`&from=set`);
  }
  
  ionViewDidLeave(){
    this.notiAnimationClass = '';
    clearInterval( this.telemetry_poller);
    clearInterval( this.terminal_modal);
    clearInterval( this.time_left_redirection_counter);
    clearInterval( this.intervalId);
  }


  page_redirection_time_left = page_redirection_time;
  
  time_left_redirection_counter : any;
  reset_time(){
    // this.notiAnimationClass = '';
    clearInterval( this.time_left_redirection_counter);
    this.page_redirection_time_left = page_redirection_time ;
  
    if( this.page_redirection_time_left = page_redirection_time){
    
    this.time_left_redirection_counter = setInterval(()=>{
      this.page_redirection_time_left -= 1;
  
      if (this.page_redirection_time_left == 0) {
        this.kiosk_apiService.terminal_dismiss()
        clearInterval(this.telemetry_poller)
        clearInterval( this.time_left_redirection_counter);
        let Autologin = this.store.get('autoLoginUrl')
        this.store.hardlogout()
        location.href = DOMAIN_URL + Autologin;

        return false;
      } 
    }, setIntervalTimeOut);
    }
   
    
  }
  
  logScrollStart(event) {
    clearInterval( this.time_left_redirection_counter);
    this.reset_time();
  
  }
  
  logScrolling(event) {
    clearInterval( this.time_left_redirection_counter);
    this.reset_time();
  
  }
  
  logScrollEnd(event) {
    clearInterval( this.time_left_redirection_counter);
    this.reset_time();
  
  }
  go_to_maintenance() {
    location.href = "http://192.168.10.11:9001";
  }

  async technical_error(err?){
    const alert =  this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: this.translatedText.oops,//'Please Tap Again',
      message: '<h2>'+ this.translatedText.redirect_back_5_seconds +'</h2>',
      buttons: [ 
  
      {
       text: '',
       cssClass: 'cross',
      handler: () => {
       this.alertController.dismiss();
       let Autologin = this.store.get('autoLoginUrl')
     // this.store.hardlogout()
     // location.href = DOMAIN_URL + Autologin;
       }
     }
      ]
      });
       setTimeout(() => { 
        let Autologin = this.store.get('autoLoginUrl')
        //this.store.hardlogout()
       // location.href = DOMAIN_URL + Autologin;
         this.alertController.dismiss();},5000);
       (await alert).present();
    }
    
    goBackToHome() {
      this.router.navigate([`home`]);
    }
    translatedText;
    getTranslatedText() {
      this.translatedText = this.store.get('translatedText');
    }

    createOrderForEvent() {

    }

    isItemCartAvaliable() {
      this.kiosk_apiService.isItemCartAvaliable().subscribe(
        (res) => {
          if (res.statusCode == 200) {
            this.create_event_kiosk_order()
          }
          else {
            clearInterval(this.time_left_redirection_counter)
            this.disableEventButton = false;
            this.soldItemModal(res)
          }
        }
      )
    }
    totalAmount = 0;
    cartItems_create_order: any = [];
    attempted;
    ServiceID;
    balance;
    deducted;
    order_UUID;
    isPaymentFailed;
    counter = 0;
    create_event_kiosk_order() {
      let cartDetail = this.store.getUserCart();
      this.totalAmount = 0;
      this.cartItems_create_order = [];
      Object.entries(cartDetail).forEach(([k, v]) => {
        Object.entries(v).forEach(([key, value]) => {
          if (v['total']) {
            this.totalAmount = v['total'] + +this.totalAmount;
          }
          this.cartItems_create_order.push(value);
        })
      });
  console.log(cartDetail)
      let order_type = "TAKE_AWAY";
      if (this.store.get("order_type") == "Dine In") {
        order_type = "DINE_IN";
      }
      let totalCartAmount = this.totalAmount;
      let user = this.store.getUser();
      this.attempted = this.totalAmount;
      this.ServiceID = this.generate_rand_num();
      let data = {
        session_id: this.store.get('sessionId'),
        order_info_uuid: this.store.get('orderId'),
        order_info_customer: user.user_information_uuid,
        master_restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
        order_info_total_price: totalCartAmount,
        order_info_total_order_quantity: 0,
        order_info_total_discount: 0,
        available_redeemed_amount: 0,
        points_redeemed_amount: 0,
        master_coupon_uuid: null,
        master_subscription_uuid: null,
        subscription_mapping_uuid: null,
        used_subscription_quantity: 0,
        order_info_gst: 0, //Number(this.gst.toFixed(2)),
        // order_info_service_tax [OPTIONAL]
        order_info_grand_total: totalCartAmount,
        order_info_delivery_date: "",
        master_restaurant_type: this.store.getRestaurant().master_restaurant_type,
        orders: this.cartItems_create_order,
        order_info_table_number: null,
        order_info_car_plate_number: null,
        order_info_cutlery: 'FALSE',
        order_info_type: order_type,//this.store.get("order_type"),
        order_info_dinningmode: null,
        order_info_dinningtime: null,
        delivery_address: null,
        is_sap_order: this.store.getRestaurant().master_restaurant_name === 'SAP' ? 'YES' : 'NO',
        country_uuid: this.store.getCountry().master_country_uuid,
        request_from: this.store.getRestaurant().order_request_from,
        deducted: this.deducted,
        balance: this.balance,
  
      }
      this.kiosk_apiService.logEvents("PAYMENT",data,"CREATE_ORDER_PAYLOAD");
      this.kiosk_apiService.audit_log("PAYMENT",data,"CREATE_ORDER_PAYLOAD");
      this.kiosk_apiService.create_kiosk_order(data).subscribe((res) => {
        if (res.statusCode == 200) {
          this.kiosk_apiService.logEvents("PAYMENT",res,"CREATE_ORDER_RES");
    this.kiosk_apiService.audit_log("PAYMENT",res,"CREATE_ORDER_RES");
  
          this.updateOrder(res, null, "QUEUED");
          this.store.set("orderId", res.aaData['order_info_uuid']);
          this.store.remove("totalAmount");
          this.store.remove("totalCartAmount");
          this.store.setUserCart([]);
          this.store.remove("selectedItemsInCart");
          if (res.statusCode != 200) {
            if (res.aaData == false) {
  
            }
          }
          if (res.aaData['master_restaurant_status'] == 'M_ON') {
            this.router.navigate([`home`]);
          } else {
          }
        } else {
          this.counter = 0
          this.maintanenceOn(res);
          this.disableEventButton = false;
          this.disableAddToCartButton = false;
        }
      }, err => {
        this.counter = 0
        this.technical_error(err)
        this.disableEventButton = false;
        this.disableAddToCartButton = false;
      })
  
    }
  
    updateOrder(orderData, paymentResponse, isPaymentFailed) {
      this.order_UUID = orderData.aaData.order_info_uuid
      this.isPaymentFailed = isPaymentFailed
      let posted_data = {
        data: { payment_type: "ELLA", used_quantity: 0, order_info_uuid: this.order_UUID, payment_info_amount: 0, master_subscription_uuid: null, subscription_mapping_uuid: null }
        , payment_type: "NA", card_type: "OTHERS"
      }
  
      let data = {
        order_info_uuid: this.order_UUID,
        restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
        payment_response: paymentResponse,
        payment_status: isPaymentFailed ? isPaymentFailed : '',
        suica_payment_details: posted_data,
      }
      this.kiosk_apiService.logEvents("PAYMENT", data, "UPDATE_ORDER_PAYLOAD");
      this.kiosk_apiService.audit_log("PAYMENT", data, "UPDATE_ORDER_PAYLOAD");
      this.kiosk_apiService.update_order(data).subscribe(res => {
        this.kiosk_apiService.logEvents("PAYMENT", res, "UPDATE_ORDER_RESPONSE");
        this.kiosk_apiService.audit_log("PAYMENT", res, "UPDATE_ORDER_RESPONSE");
        this.store.remove("orderId");
        this.store.remove("totalAmount");
        this.store.remove("totalCartAmount");
        this.store.setCart([]);
        this.store.remove("selectedItemsInCart");
        if (this.isPaymentFailed == 'QUEUED' && this.app_settings.enable_kiosk_print) {
          this.router.navigateByUrl('/settlement-complete?id=' + this.order_UUID);
        }
        else if (!this.app_settings.enable_kiosk_print && this.isPaymentFailed == 'QUEUED') {
          this.counter = 0
          clearInterval(this.time_left_redirection_counter);
          const payment_cancel_timeout = setTimeout(() => {
            let Autologin = this.store.get('autoLoginUrl')
      this.store.hardlogout()
      location.href = DOMAIN_URL + Autologin;
            
          }, 100)
  
        }
        return false;
      }, err => {
        this.counter = 0
        this.technical_error(err)
      }
      );
    }

    generate_rand_num() {
      var rand: any;
      rand = Math.random();
      rand = rand * 10000000000;
      rand = Math.floor(rand);
      rand = '' + rand;
      rand = rand.substr(0, 8);
      return rand;
    }
  
    async maintanenceOn(data) {
      this.store.setUserCart([]);
  
      let itemImage = "assets/image/bell.svg"
  
      let message = data.message
      let html = `<div class="custom-head">
        <span><img src="`+ itemImage + `"></span>
        <h2>Sorry</h2>
        <p>`+ message + `</p>
        </div>`
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class default-modal',
        mode: 'md',
        message: html,
        backdropDismiss: false,
        buttons: [
          {
            text: 'Ok',
            handler: () => {
              this.alertController.dismiss();
              this.reset_time();
            }
          }
        ]
      });
      setTimeout(() => {
        this.alertController.dismiss();
        this.reset_time();
      }, 5000);
      await alert.present();
    }

    async soldItemModal(data) {
      this.store.setUserCart([]);
      let itemImage = data.message.item_image
      if (itemImage == undefined || itemImage == '') {
        itemImage = "assets/image/bell.svg"
      }
      else {
  
        itemImage = data.message.item_image
      }
  
      let message = data.message.error
      let html = `<div class="custom-head">
        <span><img src="`+ itemImage + `"></span>
        <h2>Sorry</h2>
        <p>`+ message + `</p>
        </div>`
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class default-modal',
        mode: 'md',
        message: html,
        backdropDismiss: false,
        buttons: [
          {
            text: 'Ok',
            handler: () => {
              this.counter = 0
              this.reset_time();
              this.alertController.dismiss();
              this.disableAddToCartButton = false;
            }
          }
        ]
      });
      setTimeout(() => {
        this.counter = 0
        this.alertController.dismiss();
        this.reset_time();
        this.disableAddToCartButton = false;
      }, 5000);
      await alert.present();
    }
    payment_printing_setting() {
      let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
      let data = {
        "master_restaurant_uuid": master_restaurant_uuid
      }
      this.kiosk_apiService.payment_printing_setting(data)
        .subscribe(
          (res) => {
            this.app_settings = res.aaData.module_permissions
          },
          error => {
          }
        );
    }
    async  showAlertMessage(message) {
      const toast = await this.toastController.create({
        color: 'dark',
        duration: 2000000000,
        message: message
      });
  
      await toast.present();
    }

    async resetTimerInterval() {
      this.intervalId = setInterval(async () => {
        const timeoutDuration = 3000;
    
        // Create a timeout promise
        const timeoutPromise = new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Request timed out')), timeoutDuration)
        );
        try {
          // Ping a reliable URL and race it against the timeout
          const response = await Promise.race([
            fetch(fetchTestUrl, { method: 'HEAD', mode: 'no-cors' }),
            timeoutPromise
          ]);
        } catch (error) {
          console.log('Disconnection occurred:');
          this.reset_time(); // Call your reset_time() method here
        }
      }, 3000);
    }
    
}
